import React from 'react';
import PropTypes from 'prop-types';

const ManualMarkingSettings = ({ setFieldValue, manualDamageLabellingEnabled }) => (
  <>
    <h6>Manual marking settings</h6>
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="manualDamageLabellingEnabled"
        checked={manualDamageLabellingEnabled}
        onChange={(event) => setFieldValue('manualDamageLabellingEnabled', event.target.checked)}
      />
      <label className="form-check-label" htmlFor="manualDamageLabellingEnabled">Enable damage marking</label>
    </div>
  </>
);

export default ManualMarkingSettings;

ManualMarkingSettings.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  manualDamageLabellingEnabled: PropTypes.bool.isRequired
};

import React, { useState } from 'react';
import { routes } from '../../routes/Routes';

import { MenuPage } from '../../layouts';
import { PageTitle } from '../../components';

import { makeFileUpload } from '../../api/ajax';
import { FileUploadDropzone } from '../../containers';

const PhotoSeriesImport = () => {
  const [isUploading, setIsImporting] = useState(false);
  const [importedPhotoSeries, setImportedPhotoSeries] = useState(null);

  const handleImportPhotoSeries = (acceptedFiles) => {
    const photoSeriesArchive = acceptedFiles && acceptedFiles[0];

    if (photoSeriesArchive) {
      setIsImporting(true);
      setImportedPhotoSeries(null);
      makeFileUpload('photoSeries/import', null, photoSeriesArchive, 'file').subscribe(
        ({ response }) => {
          const { vehicleId, photoSeriesId } = response;
          setImportedPhotoSeries({
            vehicleId,
            photoSeriesId
          });
          setIsImporting(false);
        }
      );
    }
  };

  return (
    <MenuPage currentRoute={routes.photoSeriesImport}>
      <PageTitle>
        <PageTitle.Left headingText="Import photo series" />
      </PageTitle>
      <div className="row">
        <div className="col-sm-12">
          {
            isUploading ? (
              <div className="progress">
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: '75%' }}
                />
              </div>
            ) : (
              <>
                {
                  importedPhotoSeries && (
                    <div className="alert alert-success" role="alert">
                      Photo series was successfully imported!
                      <a
                        href={`/vehicles/${importedPhotoSeries.vehicleId}/photoSeries/${importedPhotoSeries.photoSeriesId}`}
                        className="alert-link"
                      >
                        Open photo series {importedPhotoSeries.photoSeriesId}
                      </a>
                    </div>
                  )
                }
                <FileUploadDropzone
                  name="Photo series import"
                  label={
                    <p>
                      Drag and drop or upload your
                      <b>.zip</b> photo series.
                    </p>
                  }
                  buttonLabel="Import photo series"
                  handleDrop={handleImportPhotoSeries}
                  accept=".zip"
                />
              </>
            )
          }
        </div>
      </div>
    </MenuPage>
  );
};

export default PhotoSeriesImport;

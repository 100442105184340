import React from 'react';
import PropTypes from 'prop-types';

import { constants as imageConstants } from '../../../../../redux/images';
import ManualMarkingSettings from './ManualMarkingSettings';

const OptionalSettings = ({ setFieldValue, imageSubTypes, imageSubType }) => {
  const imageSubTypeConfiguration = imageSubTypes.find((configuration) => configuration.imageSubType === imageSubType);
  if (!imageSubTypeConfiguration) return null;

  const handleOnChange = (optional) => {
    imageSubTypeConfiguration.optional = optional;
    setFieldValue('imageSubTypes', [
      ...imageSubTypes.filter((configuration) => configuration.imageSubType !== imageSubType),
      imageSubTypeConfiguration
    ]);
  };

  return (
    <>
      <div className="ml-4">
        <label htmlFor={`mandatory-${imageSubType}`} className="mb-0">
          <input
            type="radio"
            name={`optionalSettings-${imageSubType}`}
            checked={!imageSubTypeConfiguration.optional}
            id={`mandatory-${imageSubType}`}
            onChange={() => handleOnChange(false)}
          />
          &nbsp; Mandatory
        </label>
      </div>
      <div className="ml-4">
        <label htmlFor={`skippable-${imageSubType}`} className="mb-0">
          <input
            type="radio"
            name={`optionalSettings-${imageSubType}`}
            checked={imageSubTypeConfiguration.optional}
            id={`skippable-${imageSubType}`}
            onChange={() => handleOnChange(true)}
          />
          &nbsp; Skippable
        </label>
      </div>
    </>
  );
};

OptionalSettings.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  imageSubTypes: PropTypes.array.isRequired,
  imageSubType: PropTypes.number
};

const AdditionalManualMarkingSettings = ({ setFieldValue, imageSubTypes, manualDamageLabellingEnabled }) => {
  const firstCloseUp = imageConstants.IMAGE_SUB_TYPES.FIRST_CLOSE_UP;
  const secondCloseUp = imageConstants.IMAGE_SUB_TYPES.SECOND_CLOSE_UP;

  const firstCloseUpConfiguration = {
    imageSubType: firstCloseUp,
    instructions: [],
    optional: false
  };

  const secondCloseUpConfiguration = {
    imageSubType: secondCloseUp,
    instructions: [],
    optional: true
  };

  const handleOnChange = (imageSubType, checked) => {
    if (checked) {
      const isSecondCloseUpAllowed = imageSubType === secondCloseUp
                                     && !imageSubTypes.some((configuration) => configuration.imageSubType === firstCloseUp);

      if (isSecondCloseUpAllowed) return;

      if (!imageSubTypes.some((configuration) => configuration === imageSubType)) {
        const subTypeConfiguration = imageSubType === firstCloseUp ? firstCloseUpConfiguration : secondCloseUpConfiguration;
        setFieldValue('imageSubTypes', [
          ...imageSubTypes,
          subTypeConfiguration
        ]);
      }
    } else {
      setFieldValue(
        'imageSubTypes',
        imageSubType === firstCloseUp ? [] : imageSubTypes.filter((configuration) => configuration.imageSubType !== imageSubType)
      );
    }
  };

  return (
    <>
      <ManualMarkingSettings setFieldValue={setFieldValue} manualDamageLabellingEnabled={manualDamageLabellingEnabled} />
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="firstCloseUp"
          checked={imageSubTypes.some((configuration) => configuration.imageSubType === firstCloseUp)}
          onChange={(event) => handleOnChange(firstCloseUp, event.target.checked)}
        />
        <label className="form-check-label" htmlFor="firstCloseUp">Close-up 1</label>
      </div>
      <OptionalSettings setFieldValue={setFieldValue} imageSubTypes={imageSubTypes} imageSubType={firstCloseUp} />
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="secondCloseUp"
          checked={imageSubTypes.some((configuration) => configuration.imageSubType === secondCloseUp)}
          onChange={(event) => handleOnChange(secondCloseUp, event.target.checked)}
          disabled={!imageSubTypes.some((configuration) => configuration.imageSubType === firstCloseUp)}
        />
        <label className="form-check-label" htmlFor="secondCloseUp">Close-up 2</label>
      </div>
      <OptionalSettings setFieldValue={setFieldValue} imageSubTypes={imageSubTypes} imageSubType={secondCloseUp} />
    </>
  );
};

export default AdditionalManualMarkingSettings;

AdditionalManualMarkingSettings.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  imageSubTypes: PropTypes.array.isRequired,
  manualDamageLabellingEnabled: PropTypes.bool.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';

import { PhotoSeriesQualityRating } from '../../../../../../components';
import { constants as imagesConstants } from '../../../../../../redux/images';

const PhotoSeriesQualityRatingSection = ({ isLoadingPhotoSeriesDetails, photoSeries }) => {
  const photoSeriesImageErrors = photoSeries
    && photoSeries.errors
    && photoSeries.errors.map((errorCode) => imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[errorCode.error]);

  const PhotoSeriesQualityRatingProps = {
    isLoading: isLoadingPhotoSeriesDetails || !photoSeries,
    photoSeries,
    photoSeriesImageErrors
  };

  return <PhotoSeriesQualityRating {...PhotoSeriesQualityRatingProps} />;
};

PhotoSeriesQualityRatingSection.propTypes = {
  isLoadingPhotoSeriesDetails: PropTypes.bool.isRequired,
  photoSeries: PropTypes.object,
};

export default PhotoSeriesQualityRatingSection;

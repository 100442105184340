import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Text, Loader, SkeletonUI } from '..';
import { ReactComponent as IconDownload } from '../../assets/img/icn-download-square.svg';
import { constants as photoSeriesConstants } from '../../redux/photoSeries';
import { constants as fileConstants } from '../../redux/files';
import { formatDateTime } from '../../utils/date';

import PhotoThumbnails from '../../screens/Vehicles/containers/PhotoSeriesDetails/container/PhotoThumbnails/PhotoThumbnails';
import DefaultGallery from '../PhotoSeriesDefaultGallery/PhotoSeriesDefaultGallery';

import './sass/photoseries-images.scss';

const confirmationType = {
  accepted: 'Accepted',
  declined: 'Declined',
  unreviewed: 'Unreviewed',
};

const PhotoSeriesImages = ({
  photoSeries,
  permissions,
  isLoadingPhotoSeriesImages,
  isLoadingConfirmPhotoSeries,
  photoSeriesImages,
  confirmPhotoSeries,
  downloadFile,
}) => (
  <div className="images-section-container photo-series-card">
    <div className="images-section-container__row1">
      {isLoadingPhotoSeriesImages || !photoSeries
        ? <SkeletonUI type="radius-horizontal" maxWidth="12.5rem" />
        : (
          <>
            <div className="images-section-container__row1-col1">
              <span className="key heading">Images</span>

              {isLoadingConfirmPhotoSeries || !photoSeries ? (
                <div className="loader-relative"><Loader size="small" /></div>
              ) : (
                <span
                  className={`value heading ${
                    photoSeries.confirmationType === confirmationType.accepted
                      ? 'green'
                      : photoSeries.confirmationType === confirmationType.declined
                        ? 'red'
                        : 'black'
                  }`}
                >
                  {(photoSeries.purgedAt && `Deleted at ${formatDateTime(photoSeries.purgedAt)}`) || photoSeries.confirmationType || (
                    _.isEmpty(photoSeriesImages)
                      ? 'None submitted'
                      : confirmationType.unreviewed
                  )}
                </span>
              )}
            </div>

            <div className="images-section-container__row1-col2">
              {!_.isEmpty(photoSeriesImages) && !photoSeries?.purgedAt && (
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => {
                    const fileEntityType = photoSeriesImages.some((image) => image.attemptNo !== 1)
                      ? fileConstants.FILE_ENTITY_TYPES.PHOTO_SERIES_ZIP_ARCHIVE_WITH_ATTEMPTED_IMAGES
                      : fileConstants.FILE_ENTITY_TYPES.PHOTO_SERIES_ZIP_ARCHIVE;

                    downloadFile(photoSeries.id, fileEntityType);
                  }}
                >
                  <IconDownload className="mr-2" style={{ width: '1rem' }} />
                  <Text size={1} weight="bold" inline>Download all images</Text>
                </button>
              )}

              {!_.isEmpty(photoSeriesImages) && !photoSeries?.purgedAt && permissions.canConfirmPhotoSeries && (
              <>
                <button
                  type="button"
                  className="btn btn-success mr-1"
                  data-testid="confirmPhotoSeriesBtn"
                  disabled={photoSeries.confirmationType === confirmationType.accepted || isLoadingConfirmPhotoSeries}
                  onClick={() => confirmPhotoSeries(photoSeriesConstants.CONFIRMATION_TYPE.ACCEPTED)}
                >
                  Accept
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-testid="declinePhotoSeriesBtn"
                  disabled={photoSeries.confirmationType === confirmationType.declined || isLoadingConfirmPhotoSeries}
                  onClick={() => confirmPhotoSeries(photoSeriesConstants.CONFIRMATION_TYPE.DECLINED)}
                >
                  Decline
                </button>
              </>
              )}
            </div>
          </>
        )}
    </div>

    { photoSeries?.purgedAt || photoSeriesImages?.length < 1 || isLoadingPhotoSeriesImages ? (
      <DefaultGallery />
    ) : (
      <div className="images-section-container__row2">
        <PhotoThumbnails photoSeriesImages={photoSeriesImages} />
      </div>
    )}
  </div>
);

PhotoSeriesImages.propTypes = {
  photoSeries: PropTypes.object,
  photoSeriesImages: PropTypes.array,
  permissions: PropTypes.shape({ canConfirmPhotoSeries: PropTypes.bool, }).isRequired,
  isLoadingPhotoSeriesImages: PropTypes.bool.isRequired,
  isLoadingConfirmPhotoSeries: PropTypes.bool.isRequired,
  confirmPhotoSeries: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
};
export default PhotoSeriesImages;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { makePost } from '../../../api/ajax';

import { Field, Error } from '../../../components/Form';

const handleSubmit = (requestBody, setStatus, setSubmitting, onLoginSuccess) => {
  setStatus();
  makePost('session', requestBody).subscribe(
    ({ response }) => onLoginSuccess(response),
    ({ response: { error } }) => {
      setSubmitting(false);
      setStatus(error);
    },
  );
};

const LoginForm = ({ onLoginSuccess }) => {
  const [forgotPasswordErrorMsg, setForgotPasswordErrorMsg] = useState('');

  const history = useHistory();

  const renderErrors = (status, errors) => {
    if (!status && !errors) return null;

    let errorMessages = [];
    if (status) errorMessages = _.concat(errorMessages, status);
    if (errors && errors.email) errorMessages = _.concat(errorMessages, errors.email);
    if (errors && errors.password) errorMessages = _.concat(errorMessages, errors.password);

    return (
      <Error>
        {_.map(errorMessages, (msg, key) => (
          <p key={key}>{msg}</p>
        ))}
      </Error>
    );
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required('Email is required').email('Please enter a correct email'),
        password: Yup.string().required('Password is required'),
      })}
      onSubmit={({ email, password }, { setStatus, setSubmitting }) => handleSubmit({ email, password }, setStatus, setSubmitting, onLoginSuccess)}
      validateOnChange={false}
    >
      {({ values, errors, status, touched, isSubmitting }) => (
        <Form
          style={{ maxWidth: '22.4375rem', margin: '0 auto' }}
          onKeyPress={() => {
            if (forgotPasswordErrorMsg) {
              setForgotPasswordErrorMsg('');
            }
          }}
        >
          <Field
            name="email"
            label="E-mail"
            labelStyle={labelStyle}
            type="email"
            placeholder="Insert e-mail"
            value={values.email}
            errors={errors.email}
            touched={touched.email}
            required
          />
          <Field
            name="password"
            label="Password"
            labelStyle={labelStyle}
            type="password"
            placeholder="Insert password"
            value={values.password}
            errors={errors.password}
            touched={touched.password}
            required
          />
          <button
            type="submit"
            className="btn btn-primary"
            style={{ width: '100%', margin: '0.625rem auto' }}
            disabled={isSubmitting}
          >
            Login
          </button>

          <button
            type="button"
            className="btn-link"
            onClick={() => {
              if (values.email) {
                history.push(`/resetPassword/${values.email}`);
              } else {
                setForgotPasswordErrorMsg('Please insert your e-mail before pressing "forgot your password"');
              }
            }}
          >
            Forgot your password?
          </button>
          {!!forgotPasswordErrorMsg && <Error>{forgotPasswordErrorMsg}</Error>}

          {renderErrors(status, errors)}
        </Form>
      )}
    </Formik>
  );
};

const labelStyle = {
  display: 'block',
  textAlign: 'left',
  fontSize: '0.7rem',
  padding: '0.3125rem 0',
};

LoginForm.propTypes = {
  onLoginSuccess: PropTypes.func,
};

export default LoginForm;

import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import { formatDate } from '../../../../../utils/date';

import HasImagesCheckbox from './HasImagesCheckbox';
import HasFeedbackCommentCheckbox from './HasFeedbackCommentCheckbox';
import HasAttemptedImages from './HasAttemptedImagesCheckbox';

import { Button, Combobox, Modal, SkeletonUI } from '../../../../../components';
import { Field, Errors } from '../../../../../components/Form';
import { constants as photoSeriesConstants } from '../../../../../redux/photoSeries';
import { constants as imagesConstants } from '../../../../../redux/images';
import { constants as vehiclesConstants, actions as vehiclesActions, helpers as vehiclesHelpers, } from '../../../../../redux/vehicles';
import { selectors as workflowsSelectors, actions as workflowsActions } from '../../../../../redux/workflows';
import { selectors as tagsSelectors, actions as tagsActions } from '../../../../../redux/tags';
import { selectors as customerSelectors } from '../../../../../redux/customers';
import { useVehicleFilterContext } from '../../../Vehicles';
import { comboTypes } from '../../../../../components/Combobox/Combobox';
import { trackEvent, EVENT_TYPES } from '../../../../../api/analytics';

import { makeGet } from '../../../../../api/ajax';

const feedbackRatingItems = [
  {
    id: 1,
    name: '1',
  },
  {
    id: 2,
    name: '2',
  },
  {
    id: 3,
    name: '3',
  },
  {
    id: 4,
    name: '4',
  },
  {
    id: 5,
    name: '5',
  },
];

const photoSeriesStatusItems = Object.keys(photoSeriesConstants.STATUS).map((key) => {
  const status = photoSeriesConstants.STATUS[key];

  return {
    id: status,
    name: photoSeriesConstants.STATUS_NAME[status],
  };
});

const photoSeriesQualityRatingItems = Object.keys(photoSeriesConstants.QUALITY_RATING).map((key) => {
  const qualityRating = photoSeriesConstants.QUALITY_RATING[key];

  return {
    id: qualityRating,
    name: photoSeriesConstants.QUALITY_RATING_NAME[qualityRating],
  };
});

const damagesFilterItems = Object.keys(photoSeriesConstants.DAMAGES).map((key) => {
  const damagesFilter = photoSeriesConstants.DAMAGES[key];

  return {
    id: damagesFilter,
    name: photoSeriesConstants.DAMAGES_NAME[damagesFilter]
  };
});

const imageErrorItems = Object.keys(imagesConstants.IMAGE_VERIFICATION_ERROR).map((key) => {
  const error = imagesConstants.IMAGE_VERIFICATION_ERROR[key];

  return {
    id: error,
    name: imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[error],
  };
});

const getSelectedItems = (filterValues, comboboxItems) => {
  if (!filterValues) return null;

  return comboboxItems.filter(({ id }) => filterValues.indexOf(id) > -1);
};

const setFilterValue = (vehiclesFilter, key, value) => {
  if (!value && value !== false) {
    delete vehiclesFilter[key];
  } else {
    vehiclesFilter[key] = value;
  }
};

const setFilterValues = (vehiclesFilter, key, values) => {
  if (_.isEmpty(values)) {
    delete vehiclesFilter[key];
  } else {
    vehiclesFilter[key] = values.map(({ id }) => id);
  }
};

export const getSharedWorkflows = (sharedWorkflows) => sharedWorkflows.workflows.map(({ id, name, customerId }) => {
  const tagName = sharedWorkflows.customers.find((customer) => customer.id === customerId)?.tag || '';
  return { id, name: `${name} (${tagName})` };
});

export const getTags = (tags, customer) => tags
  .filter((tag) => tag.customerId !== customer.id)
  .map(({ value, customerId }) => ({ id: customerId, name: value }));

export const getSharedCustomers = (sharedWorkflows) => sharedWorkflows.customers.map(({ id, tag }) => ({ id, name: tag }));

const VehiclesFilterModal = ({ searchPhrase, setShowModal }) => {
  const [vehiclesFilter, setVehiclesFilter] = useVehicleFilterContext();

  const foundErrorsRef = useRef(null);
  const workflowRef = useRef(null);
  const sharedWorkflowRef = useRef(null);
  const sharedCustomersRef = useRef(null);
  const reportStatusRef = useRef(null);
  const feedbackRatingRef = useRef(null);
  const photoseriesStatusRef = useRef(null);
  const damagesFilterRef = useRef(null);
  const sharedToCustomersRef = useRef(null);
  const dispatch = useDispatch();

  const workflows = useSelector(workflowsSelectors.selectWorkFlows);
  const sharedWorkflows = useSelector(workflowsSelectors.selectSharedWorkflows);
  const customersTags = useSelector(tagsSelectors.selectCustomersTags);
  const customer = useSelector(customerSelectors.selectCurrentCustomer);

  const mapName = (name, deletedAt) => deletedAt ? `${name} (deleted ${formatDate(deletedAt)})` : `${name}`;

  const [workflowItems] = useState(() => _.map(
    _.orderBy(workflows, (w) => w.deletedAt, ['desc']),
    ({ id, name, deletedAt }) => ({ id, name: mapName(name, deletedAt) })
  ));

  const [selectedWorkflows, setSelectedWorkflows] = useState(() => getSelectedItems(vehiclesFilter.workflowIds, workflowItems));
  const [selectedSharedWorkflows, setSelectedSharedWorkflows] = useState();
  const [selectedSharedCustomers, setSelectedSharedCustomers] = useState();
  const [selectedSharedToCustomers, setSelectedSharedToCustomers] = useState();
  const [selectedFeedbackRatings, setSelectedFeedbackRatings] = useState(() => getSelectedItems(vehiclesFilter.feedbackRatings, feedbackRatingItems));
  const [selectedPhotoSeriesStatuses, setSelectedPhotoSeriesStatuses] = useState(() =>
    getSelectedItems(vehiclesFilter.photoSeriesStatuses, photoSeriesStatusItems));
  const [selectedPhotoSeriesQualityRatings, setSelectedPhotoSeriesQualityRatings] = useState(() =>
    getSelectedItems(vehiclesFilter.photoSeriesQualityRatings, photoSeriesQualityRatingItems));
  const [selectedFoundImageErrors, setSelectedFoundImageErrors] = useState(() => getSelectedItems(vehiclesFilter.foundImageErrors, imageErrorItems));
  const [hasImages, setHasImages] = useState(vehiclesFilter.hasImages);
  const [hasFeedbackComment, setHasFeedbackComment] = useState(vehiclesFilter.hasFeedbackComment);
  const [hasAttemptedImages, setHasAttemptedImages] = useState(vehiclesFilter.hasAttemptedImages);

  const [damages, setDamages] = useState(() => getSelectedItems(vehiclesFilter.damages, damagesFilterItems));

  useEffect(() => {
    if (sharedWorkflows) {
      setSelectedSharedWorkflows(getSelectedItems(vehiclesFilter.sharedWorkflowIds, getSharedWorkflows(sharedWorkflows)));
      setSelectedSharedCustomers(getSelectedItems(vehiclesFilter.sharedCustomerIds, getSharedCustomers(sharedWorkflows)));
      return;
    }

    makeGet('workflows/currentCustomer/shared').subscribe(({ response }) => {
      dispatch(workflowsActions.loadSharedWorkflowsSuccess(response));
    });
  }, [sharedWorkflows, dispatch, vehiclesFilter.sharedWorkflowIds, vehiclesFilter.sharedCustomerIds]);

  useEffect(() => {
    if (customersTags) {
      setSelectedSharedToCustomers(getSelectedItems(vehiclesFilter.sharedToCustomerIds, getTags(customersTags, customer)));
      return;
    }

    makeGet('tags').subscribe(({ response }) => {
      dispatch(tagsActions.loadCustomersTagsSuccess(response));
    });
  }, [customersTags, dispatch, vehiclesFilter.sharedToCustomerIds, customer]);

  const handleSubmit = (filterValues) => {
    const { externalId, completedOnOrAfter, completedOnOrBefore } = filterValues;

    const newVehiclesFilter = {
      ...vehiclesFilter,
    };

    setFilterValue(newVehiclesFilter, 'externalId', externalId);
    setFilterValue(newVehiclesFilter, 'completedOnOrAfter', completedOnOrAfter);
    setFilterValue(newVehiclesFilter, 'completedOnOrBefore', completedOnOrBefore);
    setFilterValue(newVehiclesFilter, 'searchPhrase', searchPhrase);
    setFilterValue(newVehiclesFilter, 'hasImages', hasImages);
    setFilterValues(newVehiclesFilter, 'feedbackRatings', selectedFeedbackRatings);
    setFilterValues(newVehiclesFilter, 'photoSeriesStatuses', selectedPhotoSeriesStatuses);
    setFilterValues(newVehiclesFilter, 'photoSeriesQualityRatings', selectedPhotoSeriesQualityRatings);
    setFilterValues(newVehiclesFilter, 'foundImageErrors', selectedFoundImageErrors);
    setFilterValues(newVehiclesFilter, 'workflowIds', selectedWorkflows);
    setFilterValues(newVehiclesFilter, 'damages', damages);
    setFilterValue(newVehiclesFilter, 'hasFeedbackComment', hasFeedbackComment);
    setFilterValue(newVehiclesFilter, 'hasAttemptedImages', hasAttemptedImages);
    setFilterValues(newVehiclesFilter, 'sharedWorkflowIds', selectedSharedWorkflows);
    setFilterValues(newVehiclesFilter, 'sharedCustomerIds', selectedSharedCustomers);
    setFilterValues(newVehiclesFilter, 'sharedToCustomerIds', selectedSharedToCustomers);

    if (vehiclesHelpers.filtersAreEqual(vehiclesFilter, newVehiclesFilter)) {
      dispatch(vehiclesActions.setPagedResultLoaded(false));
    } else {
      setVehiclesFilter({ ...newVehiclesFilter, ...vehiclesConstants.DEFAULT_SORT });

      // Track event in Analytics
      trackEvent(EVENT_TYPES.VEHICLES_FILTER_SEARCHED);
    }

    setShowModal(false);
  };

  return (
    <Modal size="lg" headerText="Add filters" backdrop="static" onHide={() => setShowModal(false)} show>
      <Modal.Body>
        <Formik
          initialValues={{
            externalId: vehiclesFilter.externalId || '',
            completedOnOrAfter: vehiclesFilter.completedOnOrAfter || '',
            completedOnOrBefore: vehiclesFilter.completedOnOrBefore || '',
          }}
          validationSchema={Yup.object().shape({
            externalId: Yup.string().max(50, 'Maximum length 50 characters.'),
          })}
          onSubmit={(formValues) => handleSubmit(formValues)}
          validateOnChange={false}
        >
          {({ values, errors, status, touched }) => (
            <Form>
              <div className="row mb-1">
                <div className="col-sm-3">
                  <Field
                    name="completedOnOrAfter"
                    label="Completed date range"
                    placeholder="Start date"
                    type="date"
                    value={values.completedOnOrAfter}
                    errors={errors.completedOnOrAfter}
                    touched={touched.completedOnOrAfter}
                  />
                </div>
                <div className="col-sm-3">
                  <Field
                    name="completedOnOrBefore"
                    label="&nbsp;"
                    placeholder="End date"
                    type="date"
                    value={values.completedOnOrBefore}
                    errors={errors.completedOnOrBefore}
                    touched={touched.completedOnOrBefore}
                    min={values.completedOnOrAfter}
                    max={new Date().toDateInputValue()}
                  />
                </div>
                <div className="col-sm-6">
                  <Field
                    name="externalId"
                    label="External ID"
                    placeholder="External ID"
                    type="text"
                    value={values.externalId}
                    errors={errors.externalId}
                    touched={touched.externalId}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-6">
                  <Combobox
                    type={comboTypes.multiCombo}
                    label="Workflow"
                    values={workflowItems}
                    initialSelection={selectedWorkflows}
                    getSelectedItems={(selectedItems) => setSelectedWorkflows(selectedItems)}
                    ref={workflowRef}
                    searchingDisabled={false}
                  />
                </div>
                <div className="col-sm-6">
                  <Combobox
                    type={comboTypes.multiCombo}
                    label="Found errors"
                    values={imageErrorItems}
                    initialSelection={selectedFoundImageErrors}
                    getSelectedItems={(selectedItems) => setSelectedFoundImageErrors(selectedItems)}
                    ref={foundErrorsRef}
                    searchingDisabled={false}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-6">
                  <Combobox
                    type={comboTypes.multiCombo}
                    label="Feedback rating"
                    values={feedbackRatingItems}
                    initialSelection={selectedFeedbackRatings}
                    getSelectedItems={(selectedItems) => setSelectedFeedbackRatings(selectedItems)}
                    ref={feedbackRatingRef}
                    searchingDisabled={false}
                  />
                </div>
                <div className="col-sm-6">
                  <Combobox
                    type={comboTypes.multiCombo}
                    label="Photoseries status"
                    values={photoSeriesStatusItems}
                    initialSelection={selectedPhotoSeriesStatuses}
                    getSelectedItems={(selectedItems) => setSelectedPhotoSeriesStatuses(selectedItems)}
                    ref={photoseriesStatusRef}
                    searchingDisabled={false}
                    testId="photoSeriesStatus"
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-6">
                  <Combobox
                    type={comboTypes.multiCombo}
                    label="Report status"
                    values={photoSeriesQualityRatingItems}
                    initialSelection={selectedPhotoSeriesQualityRatings}
                    getSelectedItems={(selectedItems) => setSelectedPhotoSeriesQualityRatings(selectedItems)}
                    ref={reportStatusRef}
                    searchingDisabled={false}
                  />
                </div>
                <div className="col-sm-6">
                  <Combobox
                    type={comboTypes.multiCombo}
                    label="Damages"
                    values={damagesFilterItems}
                    initialSelection={damages}
                    getSelectedItems={(selectedItems) => setDamages(selectedItems)}
                    ref={damagesFilterRef}
                    searchingDisabled={false}
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-sm-4">
                  <HasImagesCheckbox hasImages={hasImages} setHasImages={setHasImages} />
                </div>
                <div className="col-sm-4">
                  <HasAttemptedImages hasAttemptedImages={hasAttemptedImages} setHasAttemptedImages={setHasAttemptedImages} />
                </div>
                <div className="col-sm-4">
                  <HasFeedbackCommentCheckbox hasFeedbackComment={hasFeedbackComment} setHasFeedbackComment={setHasFeedbackComment} />
                </div>
              </div>
              <div className="row mt-3 mb-1">
                <div className="col-sm-12">
                  <h6>Shared to others</h6>
                  <hr className="mt-0 mb-0" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {
                    customersTags === undefined || selectedSharedToCustomers === undefined
                      ? <div className="mt-2"><SkeletonUI type="radius-horizontal" height="3.75rem" /></div>
                      : (
                        <Combobox
                          type={comboTypes.multiCombo}
                          label="Tags"
                          values={getTags(customersTags, customer)}
                          initialSelection={selectedSharedToCustomers}
                          getSelectedItems={(selectedItems) => setSelectedSharedToCustomers(selectedItems)}
                          ref={sharedToCustomersRef}
                          searchingDisabled={false}
                        />
                      )
                  }
                </div>
              </div>
              <div className="row mt-3 mb-1">
                <div className="col-sm-12">
                  <h6>Shared with me</h6>
                  <hr className="mt-0 mb-0" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  {
                    sharedWorkflows === undefined || selectedSharedWorkflows === undefined
                      ? <div className="mt-2"><SkeletonUI type="radius-horizontal" height="3.75rem" /></div>
                      : (
                        <Combobox
                          type={comboTypes.multiCombo}
                          label="Workflow"
                          values={getSharedWorkflows(sharedWorkflows)}
                          initialSelection={selectedSharedWorkflows}
                          getSelectedItems={(selectedItems) => setSelectedSharedWorkflows(selectedItems)}
                          ref={sharedWorkflowRef}
                          searchingDisabled={false}
                        />
                      )
                  }
                </div>
                <div className="col-sm-6">
                  {
                    sharedWorkflows === undefined || selectedSharedCustomers === undefined
                      ? <div className="mt-2"><SkeletonUI type="radius-horizontal" height="3.75rem" /></div>
                      : (
                        <Combobox
                          type={comboTypes.multiCombo}
                          label="Tags"
                          values={getSharedCustomers(sharedWorkflows)}
                          initialSelection={selectedSharedCustomers}
                          getSelectedItems={(selectedItems) => setSelectedSharedCustomers(selectedItems)}
                          ref={sharedCustomersRef}
                          searchingDisabled={false}
                        />
                      )
                  }
                </div>
              </div>

              <Errors status={status} />
              <div className="row mt-3">
                <div className="col-12 col-sm-12 text-right">
                  <Button
                    className="btn btn-secondary mr-1"
                    variation="light-blue"
                    type="reset"
                    testId="cancelModalBtn"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button className="btn btn-primary" type="submit" testId="applyVehiclesFilterBtn">
                    Apply filter
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

VehiclesFilterModal.propTypes = {
  searchPhrase: PropTypes.string,
  setShowModal: PropTypes.func.isRequired,
};

export default VehiclesFilterModal;

const NAME = 'damages';

const BODY_PART = {
  INTERNALS: 0,
  UNKNOWN: 1,
  TIRE: 2,
  WINDSHIELD: 3,
  TAILGATE_GLASS: 4,
  WIPER: 5,
  FENDER: 6,
  BACK_DOOR: 7,
  FRONT_DOOR: 8,
  MIRROR: 9,
  DOOR_GLASS: 10,
  SIGNAL_LIGHT: 11,
  HOOD: 12,
  TRUNK: 13,
  LICENSE_PLATE: 14,
  FRONT_BUMPER: 15,
  REAR_BUMPER: 16,
  LIGHT: 17,
  FOG_LIGHT: 18,
  GRILLE: 19,
  WHEEL: 20,
  BODY: 21,
  TOW_HITCH: 22,
  INNER_FENDER: 23,
  FENDER_FLARE: 24,
  SIDE_SKIRT: 25
};

const BODY_PART_NAMES = {
  [BODY_PART.INTERNALS]: 'Internals',
  [BODY_PART.UNKNOWN]: 'Unknown',
  [BODY_PART.TIRE]: 'Tire',
  [BODY_PART.WINDSHIELD]: 'Windshield',
  [BODY_PART.TAILGATE_GLASS]: 'Tailgate glass',
  [BODY_PART.WIPER]: 'Wipe',
  [BODY_PART.FENDER]: 'Fender',
  [BODY_PART.BACK_DOOR]: 'Back door',
  [BODY_PART.FRONT_DOOR]: 'Front door',
  [BODY_PART.MIRROR]: 'Mirror',
  [BODY_PART.DOOR_GLASS]: 'Door glass',
  [BODY_PART.SIGNAL_LIGHT]: 'Signal light',
  [BODY_PART.HOOD]: 'Hood',
  [BODY_PART.TRUNK]: 'Trunk',
  [BODY_PART.LICENSE_PLATE]: 'License plate',
  [BODY_PART.FRONT_BUMPER]: 'Front bumper',
  [BODY_PART.REAR_BUMPER]: 'Rear bumper',
  [BODY_PART.LIGHT]: 'Light',
  [BODY_PART.FOG_LIGHT]: 'Fog light',
  [BODY_PART.GRILLE]: 'Grille',
  [BODY_PART.WHEEL]: 'Wheel',
  [BODY_PART.BODY]: 'Body',
  [BODY_PART.TOW_HITCH]: 'Tow hitch',
  [BODY_PART.INNER_FENDER]: 'Inner fender',
  [BODY_PART.FENDER_FLARE]: 'Fender flare',
  [BODY_PART.SIDE_SKIRT]: 'Side skirt'
};

const DAMAGE_TYPE = {
  DENT: 0,
  LAMP_BROKEN: 1,
  PAINT_DAMAGE: 2,
  RUST: 3,
  SCRAPE: 4,
  SCRATCH: 5,
  CHIP: 6,
  CRACK: 7,
  OTHER_DAMAGE: 8,
  MISALIGNED: 9,
  MISSING_PART: 10,
  FALSE_POSITIVE: 100
};

const DAMAGE_TYPE_NAME = {
  [DAMAGE_TYPE.DENT]: 'Dent',
  [DAMAGE_TYPE.LAMP_BROKEN]: 'Lamp broken',
  [DAMAGE_TYPE.PAINT_DAMAGE]: 'Paint damage',
  [DAMAGE_TYPE.RUST]: 'Rust',
  [DAMAGE_TYPE.SCRAPE]: 'Scrape',
  [DAMAGE_TYPE.SCRATCH]: 'Scratch',
  [DAMAGE_TYPE.CHIP]: 'Chip',
  [DAMAGE_TYPE.CRACK]: 'Crack',
  [DAMAGE_TYPE.OTHER_DAMAGE]: 'Other damage',
  [DAMAGE_TYPE.MISALIGNED]: 'Misaligned',
  [DAMAGE_TYPE.MISSING_PART]: 'Missing part',
  [DAMAGE_TYPE.FALSE_POSITIVE]: 'False positive'
};

const SIDE = {
  FRONT: 0,
  BACK: 1,
  LEFT: 2,
  RIGHT: 3,
  FRONT_LEFT: 4,
  FRONT_RIGHT: 5,
  BACK_LEFT: 6,
  BACK_RIGHT: 7
};

const SIDE_NAME = {
  [SIDE.FRONT]: 'Front',
  [SIDE.BACK]: 'Back',
  [SIDE.LEFT]: 'Left',
  [SIDE.RIGHT]: 'Right',
  [SIDE.FRONT_LEFT]: 'Front left',
  [SIDE.FRONT_RIGHT]: 'Front right',
  [SIDE.BACK_LEFT]: 'Back left',
  [SIDE.BACK_RIGHT]: 'Back right',
};

const REPAIR_DECISION = {
  REPAIR: 0,
  REPLACE: 1
};

const REPAIR_DECISION_NAME = {
  [REPAIR_DECISION.REPAIR]: 'Repair',
  [REPAIR_DECISION.REPLACE]: 'Replace'
};

export default {
  BODY_PART,
  BODY_PART_NAMES,
  DAMAGE_TYPE,
  DAMAGE_TYPE_NAME,
  NAME,
  SIDE,
  SIDE_NAME,
  REPAIR_DECISION,
  REPAIR_DECISION_NAME
};

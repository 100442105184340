import { combineReducers } from 'redux';

import customerConfigurationsReducer, { constants as customerConfigurationsConstants } from './customerConfigurations';
import sessionReducer, { constants as sessionConstants } from './session';
import customerMonthlyConsumptionsReducer, { constants as customerMonthlyConsumptionsConstants } from './customerMonthlyConsumptions';
import imagesReducer, { constants as imagesConstants } from './images';
import photoSeriesReducer, { constants as photoSeriesConstants } from './photoSeries';
import customerReducer, { constants as customersConstants } from './customers';
import usersReducer, { constants as usersConstants } from './users';
import vehiclesReducer, { constants as vehiclesConstants } from './vehicles';
import workflowsReducer, { constants as workflowsConstants } from './workflows';
import countriesReducer, { constants as countriesConstants } from './countries';
import subscriptionReducer, { constants as subscriptionConstants } from './subscriptions';
import commentsReducer, { constants as commentsConstants } from './comments';
import translationsReducer, { constants as translationsConstants } from './translations';
import customerTranslationsReducer, { constants as customerTranslationsConstants } from './customerTranslations';
import damagesReducer, { constants as damagesConstants } from './damages';
import userAgentBlacklistReducer, { constants as userAgentBlacklistConstants } from './userAgentBlacklist';
import tagsReducer, { constants as tagsConstants } from './tags';
import accessUrlsReducer, { constants as accessUrlsConstants } from './accessUrls';
import customImageTypesReducer, { constants as customImageTypesConstants } from './customImageTypes';
import windshieldRepairabilityReducer, { constants as windshieldRepairabilityProfilesConstants } from './windshieldRepairabilityProfiles';

export const rootReducer = combineReducers({
  [customerConfigurationsConstants.NAME]: customerConfigurationsReducer,
  [sessionConstants.NAME]: sessionReducer,
  [customerMonthlyConsumptionsConstants.NAME]: customerMonthlyConsumptionsReducer,
  [imagesConstants.NAME]: imagesReducer,
  [photoSeriesConstants.NAME]: photoSeriesReducer,
  [customersConstants.NAME]: customerReducer,
  [usersConstants.NAME]: usersReducer,
  [vehiclesConstants.NAME]: vehiclesReducer,
  [workflowsConstants.NAME]: workflowsReducer,
  [countriesConstants.NAME]: countriesReducer,
  [subscriptionConstants.NAME]: subscriptionReducer,
  [commentsConstants.NAME]: commentsReducer,
  [translationsConstants.NAME]: translationsReducer,
  [customerTranslationsConstants.NAME]: customerTranslationsReducer,
  [damagesConstants.NAME]: damagesReducer,
  [userAgentBlacklistConstants.NAME]: userAgentBlacklistReducer,
  [tagsConstants.NAME]: tagsReducer,
  [accessUrlsConstants.NAME]: accessUrlsReducer,
  [customImageTypesConstants.NAME]: customImageTypesReducer,
  [windshieldRepairabilityProfilesConstants.NAME]: windshieldRepairabilityReducer
});

import React from 'react';
import PropTypes from 'prop-types';

const SmartScanCommentSettings = ({ smartScanCommentSettings, setFieldValue }) => (
  <>
    <h6>Comment settings</h6>
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="smartScanCommentsEnabled"
        checked={smartScanCommentSettings.enabled}
        onChange={(event) => {
          setFieldValue('smartScanCommentSettings', {
            ...smartScanCommentSettings,
            enabled: event.target.checked
          });
        }}
      />
      <label className="form-check-label" htmlFor="smartScanCommentsEnabled">Allow SmartScan comments</label>
    </div>
    <div className="form-check ml-4">
      <input
        className="form-check-input"
        type="checkbox"
        id="automaticPopup"
        checked={smartScanCommentSettings.automaticPopup}
        disabled={!smartScanCommentSettings.enabled}
        onChange={(event) => {
          setFieldValue('smartScanCommentSettings', {
            ...smartScanCommentSettings,
            automaticPopup: event.target.checked
          });
        }}
      />
      <label className="form-check-label" htmlFor="automaticPopup">Automatically ask</label>
    </div>
    <div className="form-check ml-4">
      <input
        className="form-check-input"
        type="checkbox"
        id="visibleInReport"
        checked={smartScanCommentSettings.visibleInReport}
        disabled={!smartScanCommentSettings.enabled}
        onChange={(event) => {
          setFieldValue('smartScanCommentSettings', {
            ...smartScanCommentSettings,
            visibleInReport: event.target.checked
          });
        }}
      />
      <label className="form-check-label" htmlFor="visibleInReport">Visible in report</label>
    </div>
  </>
);

export default SmartScanCommentSettings;

SmartScanCommentSettings.propTypes = {
  smartScanCommentSettings: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    automaticPopup: PropTypes.bool.isRequired,
    visibleInReport: PropTypes.bool.isRequired
  }),
  setFieldValue: PropTypes.func.isRequired
};

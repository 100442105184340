import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PhotoSeriesImages } from '../../../../../../components';
import { actions as photoSeriesActions, constants as photoSeriesConstants } from '../../../../../../redux/photoSeries';
import { actions as imagesActions, selectors as imagesSelectors } from '../../../../../../redux/images';
import { actions as damagesActions } from '../../../../../../redux/damages';
import { makeGet, makePost } from '../../../../../../api/ajax';
import { composeApiUrl } from '../../../../../../api/composeApiUrl';
import { trackEvent, EVENT_TYPES } from '../../../../../../api/analytics';

const downloadFile = (photoSeriesId, fileEntityType) => {
  makeGet(`files/${photoSeriesId}/token?entityType=${fileEntityType}`).subscribe(({ response }) => {
    window.location = composeApiUrl(`files/${photoSeriesId}/download?token=${response.token}`);
  });
};

const PhotoSeriesImagesSection = ({ photoSeries, permissions }) => {
  const [isLoadingPhotoSeriesImages, setIsLoadingPhotoSeriesImages] = useState(false);
  const [isLoadingConfirmPhotoSeries, setIsLoadingConfirmPhotoSeries] = useState(false);

  const { photoSeriesId } = useParams();
  const dispatch = useDispatch();

  const images = useSelector(imagesSelectors.selectImages);
  const photoSeriesImages = images && images[photoSeriesId];

  useEffect(() => {
    let isMounted = true;

    if (isMounted && !photoSeriesImages) {
      setIsLoadingPhotoSeriesImages(true);
      makeGet(`images?photoSeriesId=${photoSeriesId}&pageSize=50&sortColumn=CreatedAt&sortDirection=asc`).subscribe(
        ({ response }) => {
          setIsLoadingPhotoSeriesImages(false);
          dispatch(imagesActions.loadImagesSuccess(photoSeriesId, response.results));
          dispatch(damagesActions.loadImageDamagesSuccess(photoSeriesId, response.results));
        },
        () => setIsLoadingPhotoSeriesImages(false),
      );
    }

    return () => {
      isMounted = false;
    };
  }, [photoSeriesId, photoSeriesImages, dispatch]);

  const confirmPhotoSeries = (value) => {
    setIsLoadingConfirmPhotoSeries(true);
    makePost(`photoSeries/${photoSeriesId}/confirm`, { confirmationType: value }).subscribe(
      ({ response }) => {
        setIsLoadingConfirmPhotoSeries(false);
        dispatch(photoSeriesActions.loadPhotoSeriesSuccess(photoSeriesId, response));

        // Track event in Analytics
        const data = {
          confirmationType: value === photoSeriesConstants.CONFIRMATION_TYPE.ACCEPTED
            ? 'Accept'
            : 'Decline',
        };

        trackEvent(EVENT_TYPES.PHOTO_SERIES_CONFIRMED, data);
      },
      () => setIsLoadingConfirmPhotoSeries(false),
    );
  };

  const PhotoSeriesImagesProps = {
    photoSeries,
    permissions,
    isLoadingPhotoSeriesImages,
    isLoadingConfirmPhotoSeries,
    confirmPhotoSeries,
    downloadFile,
    photoSeriesImages,
  };

  return <PhotoSeriesImages {...PhotoSeriesImagesProps} />;
};

PhotoSeriesImagesSection.propTypes = {
  photoSeries: PropTypes.object,
  permissions: PropTypes.shape({
    canConfirmPhotoSeries: PropTypes.bool,
    canViewDamages: PropTypes.bool
  }).isRequired,
};
export default PhotoSeriesImagesSection;

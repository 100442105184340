import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PhotoSeriesImagesSection from './container/PhotoSeriesImagesSection/PhotoSeriesImagesSection';
import PhotoSeriesShareModalSection from './container/PhotoSeriesShareModalSection/PhotoSeriesShareModalSection';
import PhotoSeriesQualityRatingSection from './container/PhotoSeriesQualityRatingSection/PhotoSeriesQualityRatingSection';
import PhotoSeriesExportSection from './container/PhotoSeriesExportSection/PhotoSeriesExportSection';
import PhotoSeriesDamagesSection from './container/PhotoSeriesDamagesSection/PhotoSeriesDamagesSection';

import { ReactComponent as IconArrowBack } from '../../../../assets/img/icn-arrow-back.svg';
import { ReactComponent as IconCalendar } from '../../../../assets/img/icn-calendar.svg';
import { ReactComponent as IconShare } from '../../../../assets/img/icn-share.svg';

import {
  PageTitle,
  Text,
  SkeletonUI,
  PhotoSeriesVehicleDetails as PhotoSeriesVehicleDetailsSection,
  PhotoSeriesCustomerFeedback as PhotoSeriesCustomerFeedbackSection,
  PhotoSeriesReportButton
} from '../../../../components';
import { InfiniteComboBox } from '../../../../containers';
import { MenuPage } from '../../../../layouts';

import {
  selectors as photoSeriesSelectors,
  actions as photoSeriesActions,
  helpers as photoSeriesHelpers
} from '../../../../redux/photoSeries';

import {
  constants as fileConstants
} from '../../../../redux/files';

import { selectors as imagesSelectors } from '../../../../redux/images';
import { selectors as vehicleSelectors, actions as vehicleActions } from '../../../../redux/vehicles';
import { constants as sessionConstants } from '../../../../redux/session';
import { actions as damagesActions, selectors as damagesSelectors } from '../../../../redux/damages';
import { selectors as customerSelectors, actions as customerActions } from '../../../../redux/customers';
import { selectors as tagsSelectors, actions as tagsActions } from '../../../../redux/tags';
import { selectors as accessUrlSelectors, actions as accessUrlActions } from '../../../../redux/accessUrls';

import { routes } from '../../../../routes/Routes';
import { makeGet, makePost } from '../../../../api/ajax';
import { getTimeFrame, checkIsDateTodayOrLessThanToday, getTimeInterval } from '../../../../utils/date';
import { authenticationService } from '../../../../services';
import { trackEvent, EVENT_TYPES } from '../../../../api/analytics';
import { composeApiUrl } from '../../../../api/composeApiUrl';
import { useCanViewPhotoSeriesDamages } from './hooks/useCanViewPhotoSeriesDamages';

import '../../../../sass/photoseries-details.scss';

const downloadFile = (photoSeriesId, fileEntityType) => {
  makeGet(`files/${photoSeriesId}/token?entityType=${fileEntityType}`)
    .subscribe(({ response }) => {
      window.location = composeApiUrl(`files/${photoSeriesId}/download?token=${response.token}`);
    });
};

const completePhotoSeries = (photoSeriesId) => {
  makePost(`capture/${photoSeriesId}/completed`)
    .subscribe(() => {
      window.location.reload();
    });
};

const PhotoSeriesDetails = () => {
  const [shownEIdModal, setShownEIdModal] = useState(false);
  const [shownSharePhotoSeriesModal, setShownSharePhotoSeriesModal] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);

  const { state } = useLocation();
  const { photoSeriesId } = useParams();
  const { vehicleId } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const photoSeries = useSelector(photoSeriesSelectors.selectPhotoSeries);
  const images = useSelector(imagesSelectors.selectImages);
  const customer = useSelector(customerSelectors.selectCurrentCustomer);
  const customersTags = useSelector(tagsSelectors.selectCustomersTags);
  const accessUrls = useSelector(accessUrlSelectors.selectAccessUrlsById);

  const canViewPhotoSeriesDamages = useCanViewPhotoSeriesDamages(photoSeriesId);
  const damages = useSelector((state) => damagesSelectors.selectPhotoSeriesDamages(state, photoSeriesId));

  const currentPhotoSeries = photoSeries && photoSeries[photoSeriesId];
  const photoSeriesImages = images && images[photoSeriesId];
  const currentAccessUrl = accessUrls && currentPhotoSeries && accessUrls[currentPhotoSeries.accessUrlId];

  const { hasPermission } = authenticationService;
  const {
    CONFIRM_PHOTO_SERIES, EDIT_PHOTOSERIES, SHARE_PHOTO_SERIES, EXPORT_PHOTO_SERIES,
    VIEW_VEHICLES, CREATE_PHOTO_SERIES, CREATE_IMAGES, COMPLETE_PHOTO_SERIES, REGENERATE_REPORTS
  } = sessionConstants.PERMISSION_TYPES;

  const isPhotoSeriesShared = photoSeriesHelpers.isPhotoSeriesShared(currentPhotoSeries, customer);
  const canConfirmPhotoSeries = hasPermission(CONFIRM_PHOTO_SERIES) && !isPhotoSeriesShared;
  const canEditPhotoSeries = hasPermission(EDIT_PHOTOSERIES) && !isPhotoSeriesShared;
  const canSharePhotoSeries = hasPermission(SHARE_PHOTO_SERIES) && !isPhotoSeriesShared;
  const canExportPhotoSeries = hasPermission(EXPORT_PHOTO_SERIES) && !isPhotoSeriesShared;
  const canViewVehicles = hasPermission(VIEW_VEHICLES);
  const canCompletePhotoSeries = hasPermission(CREATE_PHOTO_SERIES)
    && hasPermission(CREATE_IMAGES)
    && hasPermission(COMPLETE_PHOTO_SERIES)
    && photoSeriesImages?.length > 0
    && currentPhotoSeries?.completedAt === null;
  const canRegeneratePhotoSeriesReports = hasPermission(REGENERATE_REPORTS);

  let timeFrame = currentPhotoSeries && getTimeFrame(currentPhotoSeries?.createdAt, currentPhotoSeries?.completedAt);
  let duration;

  if (currentPhotoSeries && photoSeriesImages?.length > 0) {
    duration = getTimeInterval('mins', photoSeriesImages[0].createdAt, photoSeriesImages[photoSeriesImages.length - 1].createdAt);
    const endDate = currentPhotoSeries.completedAt || photoSeriesImages[photoSeriesImages.length - 1].createdAt;
    timeFrame = getTimeFrame(currentPhotoSeries.createdAt, endDate);
  }

  useEffect(() => {
    if (customer) return;
    makeGet('customers/current').subscribe(({ response }) => dispatch(customerActions.loadCustomerSuccess(response)));
  }, [customer, dispatch]);

  useEffect(() => {
    if (customer && customersTags == null) {
      makeGet('tags').subscribe(({ response }) => {
        dispatch(tagsActions.loadCustomersTagsSuccess(response));
      });
    }
  }, [customer, dispatch, customersTags]);

  useEffect(() => {
    // Track event in Analytics
    trackEvent(EVENT_TYPES.VEHICLE_DETAILS_OPENED, { photoSeriesId });

    if (currentPhotoSeries) return;

    makeGet(`photoSeries/${photoSeriesId}`).subscribe(({ response }) => {
      dispatch(photoSeriesActions.loadPhotoSeriesSuccess(photoSeriesId, response));
      dispatch(damagesActions.loadPhotoSeriesDamagesSuccess(photoSeriesId, response.damages));
    });
  }, [photoSeriesId, currentPhotoSeries, dispatch]);

  useEffect(() => {
    if (!currentPhotoSeries?.accessUrlId || currentAccessUrl) return;

    makeGet(`accessUrls/${currentPhotoSeries.accessUrlId}`).subscribe(({ response }) => {
      dispatch(accessUrlActions.loadAccessUrlSuccess(currentPhotoSeries.accessUrlId, response));
    });
  }, [currentPhotoSeries, currentAccessUrl, dispatch]);

  const customRender = (item, clickHandler) => (
    <>
      {item
        && (
          <li key={item?.id} className="item" onClick={() => clickHandler(item)}>
            <span>{getTimeFrame(item.createdAt, item.completedAt)}</span>
            <Text size={1}>{item.workflow.name}</Text>
          </li>
        )}
    </>
  );

  const sharedWith = currentPhotoSeries && currentPhotoSeries.sharedWithCustomerIds && customersTags
    ? currentPhotoSeries.sharedWithCustomerIds.map((customerId) => ({
      id: customerId,
      name: customersTags.find((tag) => tag.customerId === customerId)?.value
    }))
    : [];

  return (
    <MenuPage currentRoute={routes.home}>
      <PageTitle>

        <PageTitle.Left headingText="Photo series detail view">
          <div className="page-title-col1">
            {canViewVehicles && (
              <Link to={state?.from || routes.vehicles}>
                <IconArrowBack className="mr-2 svg-small" />
                Back to list view
              </Link>
            )}
          </div>
        </PageTitle.Left>

        <PageTitle.Right>
          <div className="page-title-col2">
            {
              !currentPhotoSeries
                ? <SkeletonUI type="radius-horizontal" height="2.5rem" width="40rem" />
                : (
                  <>
                    {
                      canEditPhotoSeries && currentPhotoSeries?.accessUrl && (
                        <span className="shared-status-text pb-2 pr-2">
                          {currentPhotoSeries.accessUrl.expiresAt !== null && checkIsDateTodayOrLessThanToday(currentPhotoSeries.accessUrl.expiresAt)
                            ? 'Publishing ended!'
                            : 'Photo series is shared!'}
                        </span>
                      )
                    }

                    {canSharePhotoSeries && (
                      <div>
                        <button
                          type="button"
                          className="btn button mr-2"
                          data-variation="light-blue"
                          style={{ maxWidth: '6rem' }}
                          data-testid="sharePhotoSeriesBtn"
                          onClick={() => setShownSharePhotoSeriesModal(true)}
                          disabled={!currentPhotoSeries}
                        >
                          <span className="d-flex align-items-center">
                            <IconShare />
                            {' '}
                            Share
                          </span>
                        </button>
                      </div>
                    )}

                    {canEditPhotoSeries ? (
                      <InfiniteComboBox
                        inputId="photoseriesId"
                        loadUrl={`vehicles/${vehicleId}/photoSeries`}
                        onLoadSuccess={vehicleActions.loadVehiclePhotoSeriesSuccess}
                        resultSelector={vehicleSelectors.selectVehiclePhotoSeriesPagedResult}
                        onSelect={(selectedItems) => history.push(selectedItems.id)}
                        customRender={customRender}
                        inputIcon={<IconCalendar className="svg-md" />}
                        placeholder={`${!currentPhotoSeries ? 'Loading...' : ''}`}
                        vehicleId={vehicleId}
                        initialValue={timeFrame}
                        photoSeriesId={photoSeriesId}
                      />
                    ) : (
                      <div className="icn-box-wrapper">{timeFrame}{<IconCalendar className="svg-md ml-2" />} </div>
                    )}

                    {
                      currentPhotoSeries.reportStatus && currentPhotoSeries.purgedAt == null && (
                        <PhotoSeriesReportButton
                          photoSeriesId={currentPhotoSeries.id}
                          reportStatus={currentPhotoSeries.reportStatus}
                          onReportCreated={(reportStatus) => {
                            dispatch(photoSeriesActions.loadPhotoSeriesReportStatusSuccess(currentPhotoSeries.id, reportStatus));
                          }}
                          hasRegenerateReportsPermission={canRegeneratePhotoSeriesReports}
                          onReportGenerating={() => {
                            makeGet(`photoSeries/${currentPhotoSeries.id}`).subscribe(({ response }) => {
                              dispatch(photoSeriesActions.loadPhotoSeriesReportStatusSuccess(currentPhotoSeries.id, response.reportStatus));
                            });
                          }}
                        />
                      )
                    }

                    {
                      canCompletePhotoSeries && (
                        <button
                          type="button"
                          className="btn btn-primary ml-2"
                          data-testid="completePhotoSeriesBtn"
                          onClick={() => {
                            setIsCompleting(true);
                            completePhotoSeries(currentPhotoSeries.id);
                          }}
                          disabled={isCompleting}
                        >
                          Complete
                        </button>

                      )
                    }

                    {canExportPhotoSeries && <PhotoSeriesExportSection photoSeries={currentPhotoSeries} disabled={!currentPhotoSeries} />}

                    {currentPhotoSeries.purgedAt && (
                      <button
                        type="button"
                        className="button btn ml-2"
                        data-variation="light-blue"
                        style={{ maxWidth: '4rem' }}
                        data-testid="downloadPhotoSeriesTransferLog"
                        onClick={() => downloadFile(currentPhotoSeries.id, fileConstants.FILE_ENTITY_TYPES.PHOTO_SERIES_PURGE_TRANSFER_LOG)}
                      >
                        Logs
                      </button>
                    )}
                  </>
                )
            }
          </div>
        </PageTitle.Right>
      </PageTitle>

      <div className="photoseries-details-container">
        <>
          <div className="photoseries-details-container__row1">
            <PhotoSeriesVehicleDetailsSection
              loading={!currentPhotoSeries || !photoSeriesImages}
              shownEIdModal={shownEIdModal}
              setShownEIdModal={setShownEIdModal}
              photoSeries={currentPhotoSeries}
              permissions={{ canEditPhotoSeries, canViewDamages: canViewPhotoSeriesDamages() }}
              damages={damages}
              timeFrame={timeFrame}
              duration={duration}
              showExternalIdModal={!isPhotoSeriesShared}
              sharedWith={sharedWith}
              setShownSharePhotoSeriesModal={setShownSharePhotoSeriesModal}
              showSharedWith={canEditPhotoSeries}
              expectedVin={currentAccessUrl?.vin}
              showOwner={isPhotoSeriesShared && currentPhotoSeries?.sharedByCustomerName.length > 0}
            />
          </div>

          <div className="photoseries-details-container__row2">
            <div className="quality-rating-wrapper">
              <PhotoSeriesQualityRatingSection isLoadingPhotoSeriesDetails={!currentPhotoSeries} photoSeries={currentPhotoSeries} />
            </div>
          </div>

          {
            canViewPhotoSeriesDamages() && (
              <div className="photoseries-details-container__row3">
                <div className="damages-wrapper">
                  <PhotoSeriesDamagesSection isLoadingPhotoSeries={!currentPhotoSeries} photoSeries={currentPhotoSeries} />
                </div>
              </div>
            )
          }

          <div className="photoseries-details-container__row4">
            <PhotoSeriesCustomerFeedbackSection
              isLoadingPhotoSeriesDetails={!currentPhotoSeries}
              photoSeries={currentPhotoSeries}
            />
          </div>

          <div className="photoseries-details-container__row5">
            <PhotoSeriesImagesSection
              photoSeries={currentPhotoSeries}
              permissions={{ canConfirmPhotoSeries, canViewDamages: canViewPhotoSeriesDamages() }}
            />
          </div>
          {
            currentPhotoSeries && customersTags && (
              <PhotoSeriesShareModalSection
                photoSeries={currentPhotoSeries}
                show={shownSharePhotoSeriesModal}
                onHide={() => setShownSharePhotoSeriesModal(false)}
                customersTags={customersTags}
              />
            )
          }
        </>
      </div>
    </MenuPage>
  );
};

export default PhotoSeriesDetails;
